.index-top-page-search {
  height: calc(100% - 64px);
  align-items: center;
  display: flex;

  @include media-breakpoint-between(xs, md) {
    height: calc(100% - 56.25px);
  }
}

.index-top-page-search-row {
  padding-bottom: 10vh;
}

.bg-gradient-top {
  background: linear-gradient(180deg, #d6bb39 0%, #1e7e34 100%);
}

.page-main-title {
  color: $gray-600;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: center;

  mark {
    color: $black;
  }

  .re-search-input {
    color: $black;
    font-weight: 600;
    padding: 0.2em;
    background-color: #fcf8e3;
    border: 0;
    min-width: 2em;

    @include media-breakpoint-up(sm) {
      max-width: 65%;
    }
  }
}


.search-article {
  // Bugfix for Google Custom Search becoming bigger than the layout
  table {
    word-break: break-all;
  }
}
